<template>
  <div class="page">
    <div class="loading-icon"><i class="fa fa-spinner fa-pulse"></i></div>
    <div>加载中……</div>
  </div>
</template>

<script>
export default {
  name: "init"
}
</script>

<style lang="scss">

.page {
  font-size: 14px;
  color: #909399;
  text-align: center;

  .loading-icon {
    font-size: 60px !important;
    margin-bottom: 10px;
    margin-top: 60px;
  }
}

</style>